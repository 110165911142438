<template>
  <section class="pt-4 px-4">
    <b-container fluid>
      <b-row>
        <b-col class="text-right"
          ><h3>Markup: {{ percentFormatter(markupAverage * 10) }}</h3></b-col
        >
      </b-row>
      <b-row>
        <b-col>
          <breadcrumb
            :category-id="categoryId"
            :add-active-link="brandName"
            :custom-items="breadcrumb"
            @categoryList="setCategoryList"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="3" class="d-lg-block d-none mb-4">
          <div>
            <category-navigator
              v-if="categoryId"
              :category-id="categoryId"
              class="mb-3"
            />
            <brand-navigator
              v-if="categoryId || search || filter"
              :brand-id="brandId"
              :category-id="categoryId"
              :search="search"
              :filter="filter"
              @brandName="setBrandName"
            />
          </div>
        </b-col>
        <b-col lg="9">
          <div class="container-fluid d-lg-block d-none">
            <b-row class="mb-3 bg-white border rounded">
              <b-col lg="7" class="align-items-center d-lg-flex d-none">
                <small class="text-muted">
                  {{ itemNumber }} {{ $tc("result", itemNumber) }}
                  {{ search ? $t("for") : "" }}
                  <span v-if="search" class="main-color"
                    >"{{ search }}"</span
                  ></small
                >
              </b-col>
            </b-row>
          </div>
          <product-table :items="categoryProducts" @editProduct="editProduct" />
          <infinite-loading
            ref="InfiniteLoading"
            spinner="spiral"
            @infinite="getItemListInfiniteLoading"
          >
            <div slot="no-more"></div>
            <div slot="no-results"></div>
          </infinite-loading>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import CategoryNavigator from "@/components/CategoryNavigator";
import BrandNavigator from "@/components/BrandNavigator";
import ProductTable from "@/components/ProductTable.vue";
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "Products",
  components: {
    BrandNavigator,
    CategoryNavigator,
    Breadcrumb,
    ProductTable,
    InfiniteLoading
  },
  data() {
    return {
      brandId: this.$route.query.brandId,
      search: this.$route.query.search,
      filter: this.$route.params.filter,
      itemList: [],
      pageCurrent: null,
      pageTotal: null,
      itemNumber: null,
      brandName: this.$route.query.brand,
      categoryList: null,
      breadcrumb: [],
      selectedItemId: null
    };
  },
  beforeMount: function() {
    if (this.$route.params.pathMatch) {
      let params = this.$route.params.pathMatch.split("/");
      let categoryId = parseInt(params[params.length - 2]);
      if (categoryId) {
        this.brandId = this.categoryId;
        this.categoryId = categoryId;
        this.$route.params.brandId = this.brandId;
        this.$route.params.categoryId = this.categoryId;
        this.$route.params.pathMatch = this.strstr(
          this.$route.params.pathMatch,
          "/" + this.categoryId,
          true
        );
      }
    }
  },
  mounted() {
    if (this.search) this.setSearchBreadcrumb();
    if (this.filter) this.setFilterBreadcrumb();
  },
  computed: {
    categoryProducts() {
      return this.$store.state.products;
    },
    filterName() {
      if (Object.keys(this.$t("filter")).includes(this.filter))
        return this.$t("filter." + this.filter);
      return "";
    },
    categoryId() {
      const result = this.$route.path.split("/");
      if (result.length === 2) return null;
      return result[1] === "products" ? result[result.length - 1] : null;
    },
    markupAverage() {
      let sum = 0;
      this.itemList.forEach(function(element) {
        sum += element.markup;
      });
      return sum / this.itemList.length;
    }
  },
  watch: {
    categoryId() {
      this.resetItemList();
    },
    brandId(value) {
      if (!value) this.brandName = null;
    },
    filter(value) {
      this.filter = value;
      this.resetItemList();
      this.setFilterBreadcrumb();
    },
    "$route.query.brandId"(value) {
      this.brandId = value;
      this.resetItemList();
      if (this.search) this.setSearchBreadcrumb();
      if (this.filter) this.setFilterBreadcrumb();
    },
    "$route.query.search"(value) {
      this.search = value;
      this.resetItemList();
      this.setSearchBreadcrumb();
    }
  },
  methods: {
    strstr: function(haystack, needle, bool) {
      let pos;
      haystack += "";
      pos = haystack.indexOf(needle);
      if (pos === -1) {
        return false;
      } else {
        if (bool) {
          return haystack.substr(0, pos);
        } else {
          return haystack.slice(pos);
        }
      }
    },
    getItemListInfiniteLoading($state) {
      if (this.pageCurrent && this.pageCurrent === this.pageTotal)
        $state.complete();
      else
        this.$http.get(this.getItemEndpoint()).then(response => {
          this.itemList.push(...response.data.item);
          this.$store.commit("setCategoryProducts", response.data.item);
          this.pageCurrent = response.data.page.current;
          this.pageTotal = response.data.page.total;
          this.itemNumber = response.data.itemNumber;
          $state.loaded();
        });
    },
    getItemEndpoint() {
      let result = `${process.env.VUE_APP_API_ENDPOINT}item`;
      let queryParams = "";
      if (this.categoryId) queryParams += `categoryId=${this.categoryId}&`;
      if (this.brandId) queryParams += `brandId=${this.brandId}&`;
      if (this.search) queryParams += `search=${this.search}&`;
      if (this.filter) queryParams += `filter=${this.filter}&`;
      if (this.pageCurrent >= 1)
        queryParams += `pageNum=${this.pageCurrent + 1}&`;
      if (queryParams !== "") result += "?" + queryParams.slice(0, -1);
      return result;
    },
    resetItemList() {
      this.itemList = [];
      this.$store.commit("clearCategoryProducts");
      this.$store.commit("setCategoryKey", "");
      this.pageTotal = null;
      this.pageCurrent = null;
      this.$refs.InfiniteLoading.stateChanger.reset();
    },
    setBrandName(name) {
      this.brandName = name;
    },
    setCategoryList(list) {
      this.categoryList = list;
    },
    setSearchBreadcrumb() {
      this.breadcrumb = [
        {
          text: "Ricerca",
          to: "?search=" + this.search
        }
      ];
    },
    setFilterBreadcrumb() {
      this.breadcrumb = [
        {
          text: this.filterName,
          to: {
            name: "filter",
            params: {
              filter: this.$route.params.filter
            }
          }
        }
      ];
    },
    percentFormatter(number) {
      let currencyFormatter = new Intl.NumberFormat("it-IT", {
        style: "percent"
      });
      return currencyFormatter.format(number);
    },
    editProduct(itemId) {
      this.selectedItemId = itemId;
      this.$bvModal.show("edit-product");
    }
  }
};
</script>
