<template>
  <b-table
    striped
    hover
    :fields="fields"
    :items="itemsHighlighted"
    @row-clicked="onRowClicked"
  >
    <template #cell(image)="data">
      <img
        v-if="data.item.image"
        :src="data.item.image"
        :alt="data.item.brand + ' ' + data.item.code + ' ' + data.item.name"
        class="image"
      />
      <img
        v-else
        src="../assets/image_not_available.png"
        :alt="data.item.brand + ' ' + data.item.code + ' ' + data.item.name"
        class="image"
      />
    </template>
    <template #cell(costPrice)="data">
      {{ currencyFormatter(data.item.costPrice) }}
    </template>
    <template #cell(markup)="data">
      <span
        class="font-weight-bold"
        :class="data.item.markup > 0 ? 'text-success' : 'text-danger'"
      >
        {{ percentFormatter(data.item.markup * 10) }}
      </span>
    </template>
    <template #cell(sellPrice)="data">
      {{ currencyFormatter(data.item.sellPrice) }}
    </template>
    <template #cell(discount)="data">
      {{ percentFormatter(Math.abs(data.item.discount) * 10) }}
    </template>
    <template #cell(listPrice)="data">
      {{ currencyFormatter(data.item.listPrice) }}
    </template>
    <template #cell(goodOnHand)="data">
      {{ data.item.goodOnHand }}
      <span v-if="data.item.purchasedQuantity > 0"
        >({{ data.item.purchasedQuantity }})</span
      >
    </template>
  </b-table>
</template>

<script>
import { urlMixin } from "@/mixins/urlMixin";

export default {
  name: "ProductTable",
  mixins: [urlMixin],
  props: {
    items: {
      type: Array
    }
  },
  data() {
    return {
      fields: [
        { key: "image", label: "Immagine" },
        { key: "brand", label: "Produttore", sortable: true },
        { key: "code", label: "Codice", sortable: true },
        { key: "name", label: "Descrizione" },
        { key: "goodOnHand", label: "Inventario", sortable: true },
        { key: "markup", label: "Ricarico", sortable: true },
        { key: "sellPrice", label: "Vendita" },
        { key: "discount", label: "Sconto", sortable: true },
        {
          key: "listPrice",
          label: "Listino"
        } /*,
        { key: "condition", label: "Condizione" }*/
      ]
    };
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    /*image() {
      if (this.item.thumbImagePath)
        return (
          this.item.thumbImagePath
            .replace(
              "https://www.tecnomodel-treni.it/",
              "https://tec.imgix.net/"
            )
            .replace("300x300", "2000x2000") +
          "?auto=format,compress&w=200&h=154&fit=fill"
        );
    },*/
    // eslint-disable-next-line vue/no-dupe-keys,vue/return-in-computed-property
    itemsHighlighted() {
      let result = [];
      this.items.forEach(function(element, index) {
        result.push(element);
        if (!element.visible) result[index]["_rowVariant"] = "danger";
      });
      return result;
    },
    markupAverage() {
      let sum = 0;
      this.items.forEach(function(element) {
        sum += element.markup;
      });
      return sum / this.items.length;
    }
  },
  methods: {
    currencyFormatter(number) {
      let currencyFormatter = new Intl.NumberFormat("it-IT", {
        style: "currency",
        currency: "EUR"
      });
      return currencyFormatter.format(number);
    },
    percentFormatter(number) {
      let currencyFormatter = new Intl.NumberFormat("it-IT", {
        style: "percent"
      });
      return currencyFormatter.format(number);
    },
    onRowClicked(item) {
      this.$router.push({ name: "Product", params: { itemId: item.itemId } });
    }
  }
  /*watch: {
    itemsaaa() {
      this.items.forEach(function(element, index) {
        // if (element.visible === "0")
          this.items[index]._rowVariant = "danger";
      });
    }
  }*/
};
</script>

<style scoped>
.image {
  object-fit: contain;
  height: 40px;
  width: 80px;
}
</style>
