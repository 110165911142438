<template>
  <div class="accordion" role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button
          v-b-toggle.category
          block
          variant="link"
          class="text-decoration-none text-left"
          >Categorie
          <font-awesome-icon icon="chevron-down" class="ml-2 text-right"
        /></b-button>
      </b-card-header>
      <b-collapse id="category" :visible="categoryId > 0" role="tabpanel">
        <b-card-body>
          <category-list
            :category-list="category"
            :category-id="categoryId"
          ></category-list>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import CategoryList from "@/components/CategoryList.vue";

export default {
  name: "CategoryNavigator",
  components: { CategoryList },
  props: {
    categoryId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      category: []
    };
  },
  mounted() {
    this.getCategory();
  },
  watch: {
    categoryId(newValue) {
      this.categoryId = newValue;
      this.getCategory();
    }
  },
  methods: {
    getCategory: function() {
      let endpoint = `${process.env.VUE_APP_API_ENDPOINT}category`;
      if (this.categoryId) endpoint += `/${this.categoryId}`;
      this.$http.get(endpoint).then(response => {
        this.category = response.data;
      });
    }
  }
};
</script>
